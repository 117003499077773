import request from '@/utils/request.js'

// 学习记录
export function studyRecord(query={}) {
  return request({
    url: 'api/TheRecentStudy',
    method: 'POST',
    params: query
  })
}

//记录学习
export function clickStudy(query={}) {
  return request({
    url: 'api/clickStudy',
    method: 'POST',
    params: query
  })
}

//获取课程 
export function getUserCourseList(query={}) {
  return request({
    url: 'api/getUserCourseForCate_id',
    method: 'POST',
    params: query
  })
}

//获取分类
export function getCateForUser(query={}) {
  return request({
    url: 'api/getCateForUser',
    method: 'POST',
    params: query
  })
}

//获取试卷 
export function getExaminationsPaper(query={}) {
  return request({
    url: 'api/getExaminationsPaper',
    method: 'POST',
    params: query
  })
}

//判断题目 
export function toTheTopic(query={}) {
  return request({
    url: 'api/toTheTopic',
    method: 'POST',
    params: query
  })
}

//提交试卷
export function theirPapers(query={}) {
  return request({
    url: 'api/theirPapers',
    method: 'POST',
    params: query
  })
}

//考试记录
export function getTestRecords(query){
	return request({
	  url: 'api/getTestRecords',
	  method: 'POST',
	  params: query
	})
}

//获取错题 
export function wrongQuestions(query){
	return request({
	  url: 'api/getWrongQuestions2',
	  method: 'POST',
	  params: query
	})
}

//获取收藏
export function collectQuestions(query){
	return request({
	  url: 'api/collectQuestions',
	  method: 'POST',
	  params: query
	})
}

//获取收藏
export function getCollectQuestions(query){
	return request({
	  url: 'api/getCollectQuestions2',
	  method: 'POST',
	  params: query
	})
}

//获取答题练习题  
export function answerToPractice(query){
	return request({
	  url: 'api/answerToPractice',
	  method: 'POST',
	  params: query
	})
}

//获取答题练习题  
export function questionRecord(query){
	return request({
	  url: 'api/questionRecord',
	  method: 'POST',
	  params: query
	})
}

//获取讲义 
export function getWorldCourse(query){
	return request({
	  url: 'api/getWorldCourse',
	  method: 'POST',
	  params: query
	})
}

//重新答题
export function delPracticeRecord(query){
	return request({
	  url: 'api/delPracticeRecord',
	  method: 'POST',
	  params: query
	})
}

//记录视频进度
export function videoRecord(query){
	return request({
	  url: 'api/vedioRecord',
	  method: 'POST',
	  params: query
	})
}

//获取课程分类
export function getQuestionCate(query){
	return request({
	  url: 'api/getQuestionCate',
	  method: 'POST',
	  params: query
	})
}

// 获取案例题
export function getCaseQuestions(query){
	return request({
	  url: 'api/getCaseQuestions',
	  method: 'POST',
	  params: query
	})
}
// 判断案例题
export function practiceCase(query){
	return request({
	  url: 'api/practiceCase',
	  method: 'POST',
	  params: query
	})
}

//取消错题
export function concalFailFun(query){
	return request({
		url:'api/deleteWrongQuestions',
		method:'POST',
		params:query
	})
}